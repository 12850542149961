.navbar {
  .navbar-collapse {
    flex-wrap:wrap;
  }
}

.header-topbar {
  line-height: 20px;
  font-family: $subheading-font;
  cursor: default !important;
  letter-spacing: 0.5px;
  font-size: 15px;
  transition:all 350ms linear;
  p {
    display:inline;
    margin:0;
    margin-bottom:0.5rem;
    &:last-of-type {
      margin-bottom:0;
    }
    @include media-breakpoint-up(lg) {
      margin:0;
      margin-bottom:0;
    }
  }
  @include media-breakpoint-down(xs) {
    font-size: 16px !important;
  }
  .nav-emergency {
    display: none;
    color: white;
    padding-right: 10px;
    font-size: 18px;
    font-weight: normal !important;
    text-transform: uppercase !important;
    @include media-breakpoint-up(lg) {
      display: inline-block;
    }
  }
  .primary-btn {
    background: $brand-primary-offset;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.05em;
    margin-right: 10px;
    text-shadow: none;
    box-shadow: none;
    border: 0px;
    padding: 5px 10px 5px 10px;
    text-transform: none;
    &:after {
      display: none;
    }
  }
  .social-media-link {
    color: #fff;
    padding-right: 10px;
    position: relative;
    display: inline-block;
    margin-top: 0px;
    &:hover {
      color: #fff;
      opacity: 0.8;
    }
  }
  .phone {
    display: inline-block;
    font-size: 15px;
    margin-left: 15px;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
    .sub-heading {
      color: $accent;
      margin: 0px;
      letter-spacing: normal;
      font-size: 12px;
      display: block;
    }
  }
  // Topbar Styles
  &.topbar-attached {
    flex-basis:100%;
  }
  // Hide on scroll
  &.topbar-hide {
    &.topbar-scrolling {
      display:none;
    }
  }
}

.left-triangle-white {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index:3;
}
.left-triangle-primary {
  position: absolute;
  bottom: 1%;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index:3;
}

.left-triangle-white svg {
  position: relative;
  display: block;
  width: calc(213% + 1.3px);
  height: 198px;
}
.left-triangle-primary svg {
  position: relative;
  display: block;
  width: calc(213% + 1.3px);
  height: 198px;
}

.left-triangle-white .shape-fill {
  fill: #FFFFFF;
}

.left-triangle-primary .shape-fill-primary {
  fill: $brand-primary;
}

.right-triangle-dark {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index:2;
}
.right-triangle-primary{
  position: absolute;
  bottom: 2%;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index:2;
}

// .custom-shape-divider-bottom-1692803524 svg {
//   position: relative;
//   display: block;
//   width: calc(213% + 1.3px);
//   height: 198px;
// }
// .custom-shape-divider-bottom-1692803525 svg {
//   position: relative;
//   display: block;
//   width: calc(213% + 1.3px);
//   height: 198px;
// }

.right-triangle-dark .shape-fill-dark {
  fill:$dark;
}

// .right-triangle-primary .shape-fill-primary {
//   fill: $brand-primary;
// }
@include media-breakpoint-up(xl){
.menu-residential .dropdown-menu {
  min-width: 30em !important;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
}
.w-85 {
  width: 85% !important;
}