// Theme Colors/Branding
$brand-primary: #FBD201;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #63768D;
$dark: #04151F;
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #FCFCFC;
$accent: #FCFCFC;
$accent-2: #1459BA;
$body-font: #222;
$error: #d81e00;
$brand-primary-dark:darken(#FBD201, 5%);