/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Grid Adjustments
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1700px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1800px
);

// Spacer Adjustments
$spacer: 1.5rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
  9: $spacer * 10,
  10: $spacer * 12.5,
  11: $spacer * 15,
  12: $spacer * 17.5,
  13: $spacer * 20,
  14: $spacer * 25,
  15: $spacer * 30
);

// Ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "3x4": calc(4 / 3 * 100%)
);

// Actions
$transition-fast: all 0.15s ease-in;
$transition-med: all 0.3s ease-in;
$transition-slow: all 0.5s ease-in;

$rounded-border: 3px;

$padding-light: 1rem;
$padding-med: 2rem;
$padding-heavy: 3rem;

// Add Bootstrap Utility Functionality
// https://getbootstrap.com/docs/5.0/utilities/api/#using-the-api
@import "~bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "position": (
      property: position,
      class: position,
      responsive: true,
      values: static absolute relative fixed sticky,
    ),
    "text-shadow": (
      property: text-shadow,
      class: text-shadow,
      responsive: true,
      values: (
        null: 0 0 3px rgba(0,0,0.4),
      ),
    ),
    "color": (
      property: color,
      class: color,
      responsive: true,
      values: white black primary secondary accent dark,
    )
  )
);

// Mixins
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
$border-radius: 0px;